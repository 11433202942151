import * as React from 'react';

// import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import Container from '@mui/material/Container';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
import { Divider, Fab, Stack, SvgIcon } from '@mui/material';

//import { useNavigate } from 'react-router-dom';
// import { auth } from '../firebase.config';

import {ReactComponent as WhatsAppIcon} from '../resources/whatsAppLogo.svg'; // Brand Logo for application
import {ReactComponent as TelephoneIcon} from '../resources/red-phone-svgrepo-com.svg'; // Brand Logo for application used from 
// reference to red phone SVG is - https://www.svgrepo.com/svg/362135/phone?edit=true
import CarousalHomePg from '../components/CarousalHomePg';
import NavBar from '../components/NavBar';
import NewCarousalForHmPg from '../components/NewCarousalForHmPg';

export default function HomePg(){

    return(
        <> 
        {/* Add Nav Bar header */}

       <NavBar />


        {/* Add slider here */}
        <Typography variant='h5' sx={{            ml:15, 
            mt:3, mr:15, color:'green'}}> 
            We are a Leading Battery Solutions Supplier in Pune region. We deal in all major
            battery brands and offer customized and/or Turn-key Battery Power solutions for 
            Domestic as well as Commercial purposes.
        </Typography>
        <Box sx={{flexGrow: 1, maxHeight: 325, maxWidth:700, border:1, borderColor:'lightgreen',
            ml:10, 
            mt:3,  mb:2,
            p:3}} 
            justifyContent={'center'} alignItems={'center'}>

            {/* <CarousalHomePg /> */}
            <NewCarousalForHmPg/>

        </Box>
        {/* <NewCarousalForHmPg/> */}

        <Divider />

        {/* Add body parts here */}
        
        <Box sx={{border:1, borderColor:'lightgreen', maxWidth:800,
            ml:15, 
            mt:3, mr:15, mb:5,
            color:'green',
            }}>
            <Typography variant='h3' sx={{color:'red', mt:3, ml:3}}> Are you facing
             Battery power issue? 
            <b> No worries !!</b>
            </Typography>
            <Typography variant='h5' mt={3} ml={5}>
                We are here, to help you out. We deal in Battery Power Solutions 
                for Inverters / UPS, Automotives and Solar batteries.
            </Typography>
            <Typography variant='h3' sx={{color:'blue', mt:3, ml:3}}>
                What do we do?
            </Typography>
            <Typography variant='h5' sx={{mt:3, ml:5}}>

                <ul>
                    <li> New Battery or Battery bank installations</li>
                    <li> Annual Maintenance contracts</li>
                    <li> Disposal of existing dysfunctional batteries against a replacement by a New one </li>
                    <li> Bulk Battery orders processing </li>
                </ul>
            </Typography>
        </Box>

        <Fab 
            sx={{
                position: "fixed",
                bottom: (theme) => theme.spacing(2),
                left: (theme) => theme.spacing(2),
                border: 1,
                bgcolor:'#ffff8d',
            }}
            //variant='extended'
          >
                <IconButton href="tel:+919422652285">
                            <TelephoneIcon/>

                            {/* &nbsp; Call Us */}
                </IconButton>
                {/* <Button variant='text' href='tel:+919422652285'>Call Us</Button> */}
                
        </Fab>

        <Fab
            sx={{
                position: "fixed",
                bottom: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(2),
                border: 1,
                bgcolor:'#ccff90',
            }}
            //variant='extended'
        
        >   

                <IconButton href="https://wa.me/9421018019">
                            <WhatsAppIcon/> 
                            {/* &nbsp; WhatsApp */}
                </IconButton>
                
        </Fab>

            <Divider/>
        {/* Add footer here */}

        <Box sx={{border:1, borderColor:'lightgreen', maxWidth:800,
            ml:15, 
            mt:3, mr:15, mb:5,
            color:'green',
            }}>

            <Typography variant='body' sx={{color:'green'}}>

                We are currently serving only in Pune city and PCMC area. We soon will be starting in other cities as well.

            </Typography>




        
        </Box>
        <Divider/>
        <Typography variant='body' fontSize={10} m={15}>
                * All the Logos and trademark showcased on this website are owned by respective 
                organisations / companies. 
            </Typography>
        <p></p>
            <p></p>
        
        
        
        
        </>
    );

}