import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Divider } from '@mui/material';

//import { useNavigate } from 'react-router-dom';
// import { auth } from '../firebase.config';

import Logo from '../resources/ReUrja_Logo.png'; // Brand Logo for application
import BatteryLogo from '../resources/battery-navbar-logo.svg';


export default function NavBar(){

    return(
        <> 
        {/* Add Nav Bar header */}

        <AppBar position="sticky"
        //color= 'transparent' 
        sx={{bgcolor:'#ccff90'}}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
            <Box
                    component="img"
                    sx={{
                    maxHeight: 54,
                    maxWidth: 300,
                    }}
                    alt="Battery"
                    src={BatteryLogo}
                    //onClick={() => navigateToHome('/')}
                />
                    
                <Box sx={{ flexGrow: 1, display: { xs: 3, md: 'flex' }, 
                        justifyContent:'center', alignItems:'center'}} >
                    <Typography variant='h5' color={'green'}><b>Battery As A Service</b></Typography>
                </Box>

                    
                    <Box
                        component="img"
                        sx={{
                        maxHeight: 54,
                        maxWidth: 300,
                        }}
                        alt="Your logo."
                        src={Logo}
                        //onClick={() => navigateToHome('/')}
                    />
                    

                    
                    {/* <Box sx={{ mr:2 }}>
                        <Button variant='contained'
                        onClick={() => navigateToHome('membership')}> 
                                Join Us
                        </Button>
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Admin ONLY">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                {/* <Avatar alt="Your Name" src="/static/images/avatar/2.jpg" /> */}
                                {/* <Avatar alt="PMA Admin" src={LogoJpeg} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
 
                                    <MenuItem key={setting} 
                                    onClick={setting === 'Login' ? handleLogin : handleLogout}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>  
 
                            ))}
                        </Menu>
                    </Box> */}

                    {/* draw a header/footer line component */}
        

                </Toolbar>
                
                {/* draw a header/footer line component */}
                <Box sx={{ bgcolor: 'green', 
                    flexGrow: 1,
                    height: '0.1rem', width: 'auto'}} />
                

            </Container>
        </AppBar>
               </>
    );

}