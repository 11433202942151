

// library reference for this carousal library is https://www.npmjs.com/package/react-responsive-carousel

import React from "react";
import { Component } from 'react';
import ReactDOM from 'react-dom';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
import ExideLogo from '../resources/Exide logo.png';
import AmaraLogo from '../resources/Amaron logo.png';
import LiveGardLogo from '../resources/LiveGuard logo.png'

import Carousel from 'react-material-ui-carousel'   // Documentation reference https://www.npmjs.com/package/react-material-ui-carousel
import { Paper } from "@mui/material";


export default function NewCarousalForHmPg() {

    
        const arrayOfImgs = [
            <img src= {ExideLogo} alt="new"/>,
            <img src= {AmaraLogo} alt="new"/>,
            <img src= {LiveGardLogo} alt="new"/>
        ];



        //console.log("Array is >>", items);
    
        return (
            //<Box sx={{border: 2, bordercolor: 'primary.main', pl: 5, ml: 20, mr:20, pr: 5}}>
            <Carousel  height  ={200} Animation='fade' duration={200}>
                {/* {
                    items.map( (item, i) => <Item key={i} item={item} /> )
                } */}
                {arrayOfImgs}
            </Carousel>
           // </Box>
        )
}